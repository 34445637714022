import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [2];

export const dictionary = {
		"/cba_dk": [~5,[2],[3]],
		"/cba_dk/about-us": [~7,[2],[3]],
		"/cba_dk/become-partner": [~8,[2],[3]],
		"/cba_dk/campaign": [~9,[2],[3]],
		"/cba_dk/campaign/[slug]": [~10,[2],[3]],
		"/cba_dk/create-profile": [~11,[2],[3]],
		"/cba_dk/download-app": [~12,[2],[3]],
		"/cba_dk/faq": [~13,[2],[3]],
		"/cba_dk/find-partners": [~14,[2],[3]],
		"/cba_dk/find-partners/[uuid]": [~15,[2],[3]],
		"/cba_dk/forgot-password": [~16,[2],[3]],
		"/cba_dk/how-it-works": [~17,[2],[3]],
		"/cba_dk/login": [~18,[2],[3]],
		"/cba_dk/my-account": [~19,[2,4],[3]],
		"/cba_dk/my-account/cashback-claim": [~20,[2,4],[3]],
		"/cba_dk/my-account/information": [~21,[2,4],[3]],
		"/cba_dk/my-account/password": [22,[2,4],[3]],
		"/cba_dk/my-account/payment-cards": [~23,[2,4],[3]],
		"/cba_dk/my-account/transactions": [~24,[2,4],[3]],
		"/cba_dk/new-partners": [~25,[2],[3]],
		"/cba_dk/privacy-policy": [~26,[2],[3]],
		"/cba_dk/purchase-access": [~27,[2],[3]],
		"/cba_dk/see-partners": [~28,[2],[3]],
		"/cba_dk/support": [~29,[2],[3]],
		"/cba_dk/terms-conditions": [~30,[2],[3]],
		"/cba_dk/[...path]": [6,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';